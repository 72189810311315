import React from 'react';
import {graphql, HeadProps, PageProps} from 'gatsby';

import {IHeroSection} from "../components/templates/hero/hero-section.interface";
import {ISpecialOfferSection} from "../components/templates/special-offer/special-offer.interface";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import {IFeatureSection} from "../components/templates/features-z-pattern/feature-section.interface";
import Layout from "../components/Layout";
import Hero from "../components/templates/hero/Hero";
import FeaturesZPattern from "../components/templates/features-z-pattern/FeaturesZPattern";
import SpecialOffer from "../components/templates/special-offer/SpecialOffer";
import Help from "../components/templates/help/Help";
import {SiteMetadata} from "../types/site-metadata.type";
import ApplicationsImageSlider from "../components/monitoring/applications-image-slider/ApplicationsImageSlider";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {PageContextType} from "../types/page-context.type";
import StructuredData from '../assets/structured-data';

type MonitoringProps = {
  strapiFunctionality: {
    slug: string
    hero: IHeroSection,
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
    properties: IFeatureSection[],
    siteMetadata: SiteMetadata,
    crumbLabel: string,
  }
} & SiteMetadataConfigProps

const Monitoring = ({data, pageContext}: PageProps<MonitoringProps, PageContextType>) => {
  const {hero, properties, specialOffer, help, siteMetadata} = data.strapiFunctionality;

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <Hero title={hero.title} image={hero.image}/>
      <FeaturesZPattern features={properties} id={'features-section'} />
      <ApplicationsImageSlider />
      <SpecialOffer specialOffer={specialOffer} />
      <Help helpSection={help} />
    </Layout>
  )
}

export default Monitoring;

export const Head = (props: HeadProps<MonitoringProps>) => {
  const slugs = props.location.pathname.split('/');
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiFunctionality.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: 'Monitoring pojazdów',
          item: siteUrl + '/' + slugs[1]
        },
        {
          name: crumbLabel,
          item: siteUrl + '/' + slugs[1] + '/' + slugs[2] + '/'
        }
      ])}
    </>
  )
}

export const pageQuery = graphql`
 query FunctionalityPage($slug: String) {
  site {
    siteMetadata {
      siteUrl
      title
    }
  }
  strapiFunctionality(slug: {eq: $slug}) {
      siteMetadata {
        crumbLabel
        title
        description
      }
      hero {
        title
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      properties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      help {
        title
        number
        email
        description
      }
      specialOffer {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
}
`;
