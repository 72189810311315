import React from "react";

type PaginatorProps = {
  currentIndex: number,
  pages: number,
  goToPage: (slideNumber: number) => void,
  goToPrev: () => void,
  goToNext: () => void,
  bulletClassName: string,
  activeBulletClassName: string,
}

const BulletPaginator = (props: PaginatorProps) => {
  const {currentIndex, pages, goToPage, activeBulletClassName, bulletClassName} = props;
  const startState = currentIndex < 3 && pages > 10;
  const endState = currentIndex > pages - 4 && pages > 10;
  const middleState = !startState && !endState && pages > 10;

  return (
    <>
      {startState &&
        <>
          {Array(4).fill('').map((_, index) => {
            return (
              <button
                id={`${index + 1}`}
                type={'button'}
                key={index}
                onClick={() => goToPage(index)}
                className={`cursor-pointer transition rounded-full ${index === currentIndex ? activeBulletClassName : bulletClassName}`}
              />
            )
          })}
          <span className={'text-neutrals-40'}>...</span>
          <button
            type={'button'}
            onClick={() => goToPage(pages - 1)}
            className={`cursor-pointer transition rounded-full ${pages - 1 === currentIndex ? activeBulletClassName : bulletClassName}`}
          />
        </>
      }

      {middleState &&
        <>
          <button
            type={'button'}
            onClick={() => goToPage(0)}
            className={`cursor-pointer transition rounded-full ${0 === currentIndex ? activeBulletClassName : bulletClassName}`}
          />
          <span className={'text-neutrals-40'}>...</span>
          <button
            type={'button'}
            onClick={() => props.goToPrev()}
            className={`cursor-pointer transition rounded-full ${bulletClassName}`}
          />
          <button
            type={'button'}
            className={`cursor-pointer transition rounded-full ${activeBulletClassName}`}
          />
          <button
            type={'button'}
            onClick={() => props.goToNext()}
            className={`cursor-pointer transition rounded-full ${bulletClassName}`}
          />
          <span className={'text-neutrals-40'}>...</span>
          <button
            type={'button'}
            onClick={() => goToPage(pages - 1)}
            className={`cursor-pointer transition rounded-full ${pages - 1 === currentIndex ? activeBulletClassName : bulletClassName}`}
          />
        </>
      }

      {endState &&
        <>
          <button
            type={'button'}
            onClick={() => goToPage(0)}
            className={`cursor-pointer transition rounded-full ${0 === currentIndex ? activeBulletClassName : bulletClassName}`}
          />
          <span className={'text-neutrals-40'}>...</span>
          {[...Array(4).keys()].reverse().map((index) => {
            return (
              <button
                type={'button'}
                key={index}
                onClick={() => goToPage(pages - (index + 1))}
                className={`cursor-pointer transition rounded-full ${pages - (index + 1) === currentIndex ? activeBulletClassName : bulletClassName}`}
              />
            )
          })}
        </>
      }
    </>
  )

}

export default BulletPaginator;
