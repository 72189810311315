import React, {useRef, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import Slider, {Settings} from "react-slick";

import Card, {SlideProps} from "./Card";
import BulletPaginator from "../../shared/slider/bullet-paginator/BulletPaginator";

type ApplicationsImageSliderProps = {
  strapiApplicationImageSlider: {
    title: string,
    applications: SlideProps[]
  }
}

const ApplicationsImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCustomPagination, setShowCustomPagination] = useState(false);
  const sliderRef = useRef<Slider>(null);

  const sliderSettings: Settings = {
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    dotsClass: 'dots',
    arrows: false,
    infinite: true,
    beforeChange: (_, i) => setCurrentIndex(i),
    onReInit: () => setShowCustomPagination(false),
    responsive: [
      {
        breakpoint: 1570,
        settings: {
          onReInit: () => setShowCustomPagination(false),
          slidesToShow: 4,
          slidesToScroll: 4,
          dotsClass: 'dots',
          arrows: false,
        }
      },
      {
        breakpoint: 1250,
        settings: {
          onReInit: () => setShowCustomPagination(false),
          slidesToShow: 3,
          slidesToScroll: 3,
          dotsClass: 'dots',
          arrows: false,
        }
      },
      {
        breakpoint: 900,
        settings: {
          onReInit: () => setShowCustomPagination(false),
          slidesToShow: 2,
          slidesToScroll: 2,
          dotsClass: 'dots',
          arrows: false,
        }
      },
      {
        breakpoint: 639,
        settings: {
          onReInit: () => setShowCustomPagination(true),
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dotsClass: 'dots',
        }
      },
    ]
  }
  const {strapiApplicationImageSlider: {title, applications}} = useStaticQuery<ApplicationsImageSliderProps>(query);

  return(
    <section className={'max-w-[1520px] ml-auto mr-auto text-center pb-36 pt-14 px-10 flex flex-col'}>
      <h2 className={'font-head text-5xl text-black mb-11 lg:mb-14'}>{title}</h2>
      <Slider ref={sliderRef} {...sliderSettings}>
        {applications.map((application, index) => {
          return(
            <Card key={index} image={application.image} label={application.label} url={application.url} green={application.green} />
          )
        })}
      </Slider>
      {showCustomPagination &&
        <div className={'flex flex-row gap-2.5 items-center ml-auto mr-auto mt-9'}>
          <BulletPaginator
            goToPrev={sliderRef?.current?.slickPrev || (() => {})}
            goToNext={sliderRef?.current?.slickNext || (() => {})}
            goToPage={sliderRef?.current?.slickGoTo || (() => {})}
            currentIndex={currentIndex}
            pages={applications.length}
            bulletClassName={'bg-neutrals-60 w-4 h-4'}
            activeBulletClassName={'bg-primary-60 w-5 h-5'}
          />
        </div>
      }
    </section>
  )
}

export default ApplicationsImageSlider;

export const query = graphql`
  query {
    strapiApplicationImageSlider {
      title
      applications {
        green
        url
        label
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
`
